<template>
  <!-- 快递单设置发件人弹窗 -->
  <div>
    <my-dialog-3
      :centerDialogVisible="dialogExpressConsignerVisible"
      :popupWidth="'500px'"
      @updateVisible="updateExpressConsignerVisible"
      @openDialog="openExpressConsignerDialog"
    >
      <template slot="content">
        <div class="consigner-dialog">
          <span style="margin-bottom: 30px">请选择快递单发件人</span>
          <el-radio-group v-model="activeExpressConsignerIndex">
            <el-radio
              v-for="(item, index) in expressConsignerList"
              :key="index"
              :label="index + ''"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
          <span v-show="expressConsignerList.length == 0"
            >暂无发件人信息，点击<el-button type="text" @click="jumptoSetting()"
              >这里</el-button
            >进行设置</span
          >
        </div>
      </template>
      <template slot="footer">
        <el-button
          class="footer-btn btn1"
          type="primary"
          @click="handleChooseExpressConsigner"
          size="mini"
          >确定</el-button
        >
        <el-button
          class="footer-btn btn2"
          size="mini"
          @click="handleCancel"
          >取消</el-button
        >
      </template>
    </my-dialog-3>
    <!-- 打印快递单弹窗 -->
    <my-dialog-3
      :centerDialogVisible="dialogExpressVisible"
      :popupWidth="'500px'"
      @updateVisible="updateExpressVisible"
    >
      <template slot="content">
        <div class="express-print-dialog">
          <el-form
            label-position="right"
            label-width="120px"
            :model="expressPrintOption"
          >
            <el-form-item v-if="this.type==1" label="打印行数：">
              <span>共{{ selectRowLength }}行</span>
            </el-form-item>
            <el-form-item v-if="this.type==1" label="快递模板：">
              <el-button type="text" style="margin-right: 20px">{{
                expressTemplateList[expressTemplate].express_name
              }}</el-button>
            </el-form-item>
            <el-form-item label="选择打印机：">
              <el-select
                v-model="expressPrintOption.printer"
                placeholder="选择打印机"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in printerList"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设置打印份数：">
              <el-select
                v-model="expressPrintOption.num"
                placeholder="选择份数"
                size="mini"
              >
                <el-option label="1" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template slot="footer">
        <!-- <el-button type="text" style="margin-right:20px;">预览</el-button> -->
        <el-button
          class="footer-btn btn1"
          type="primary"
          size="mini"
          @click="DetectPrintedExpress"
          >打印快递单</el-button
        >
        <el-button
          class="footer-btn btn2"
          size="mini"
          @click="dialogExpressVisible = false"
          >取消</el-button
        >
      </template>
    </my-dialog-3>
    <!-- 已打印过的快递单提示框 -->
    <reprintDialog2 v-if="dialogShow5" @use_new="doPrintExpress(0)" @use_old="doPrintExpress(1)" @cancel="()=>{this.dialogShow5=false}" />
       <!-- 打印失败提示框 -->
    <GetExpressErrorPopup v-if="GetExpressError" :dataSrc="GetExpressError" @closePopup="()=>{this.GetExpressError=false}"></GetExpressErrorPopup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GetExpressErrorPopup from "@/UI/GetExpressErrorPopup";
import myDialog3 from "@/UI/myDialog3";
import reprintDialog2 from "@/components/dialog/reprintDialog2";
export default {
    data() {
      let printerList = new Array();
      printerList = printerList.concat(
        this.PRINTERLIST || [], 
        window.pinduoduo_print_list || [], 
      )
      printerList = printerList.filter((item, index) => printerList.indexOf(item) === index)
      console.log(printerList, '---printerList')
      return {
        // 打印机列表
        printerList, 
        // 快递单发货人设置弹窗变量
        activeExpressConsignerIndex: "0",
        // 快递打印确认弹窗变量
        dialogExpressVisible: false,
        // 快递打印确认弹窗绑定数据
        expressPrintOption: {
          printer: "",
          num: "1",
        },
        GetExpressError: false, // 快递打印获取单号错误
        dialogShow5: false, // 判断是否有打印过的快递单
      }
    },
    // type:1批量 type:2单个
    props:["selectRowLength",'orderInfoList','has_express_print_state','type'],
    components:{
      myDialog3,
      GetExpressErrorPopup,
      reprintDialog2
    },
    computed:{
      ...mapState({
          expressConsignerList: (state) => state.print.expressConsignerList,
          expressTemplateList: (state) => state.print.expressTemplateList,
          // 打印参数
          printOrderInfo: (state) => state.batchprint.printOrderInfo,
          }),
          expressTemplate: {
            get() {
              return this.$store.state.batchprint.expressTemplate;
            },
            set(val) {
              this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val);
            },
          },
          dialogExpressConsignerVisible:{
            get(){
              return this.$store.state.batchprint.dialogExpressConsignerVisible
            }
          }
    },
    methods: {
      handleCancel(){
        this.$store.commit('batchprint/SAVE',{dialogExpressConsignerVisible:false})
      },
      // 跳转设置
      jumptoSetting() {
        this.$router.push("/setting");
      },
      // 快递单发件人设置弹窗函数
      updateExpressConsignerVisible(val) {
        this.$store.commit('batchprint/SAVE',{dialogExpressConsignerVisible:val})
      },
      openExpressConsignerDialog() {
        this.$store.dispatch("print/get_express_consigner_list");
      },
      handleChooseExpressConsigner() {
        let expressConsigner = this.expressConsignerList[
        this.activeExpressConsignerIndex
        ];
        this.expressConsigner = expressConsigner;
        this.$store.commit('batchprint/SAVE',{dialogExpressConsignerVisible:false})
        this.dialogExpressVisible = true;
      },
      // 快递弹窗函数
      updateExpressVisible(val) {
        this.dialogExpressVisible = val;
      },
      // 判断是否存在打印过订单
    DetectPrintedExpress(){
      this.dialogExpressVisible = false
      if(!this.expressPrintOption.num || !this.expressPrintOption.printer ){
        this.$message.error("请选择打印机和打印份数");
        return false;
      } else if(this.has_express_print_state || this.orderInfoList.express_print_state==1 ){
          this.dialogShow5 = true
      } else {
        this.doPrintExpress(0)
      }
    },
     // 打印函数
    doPrintExpress(is_old) {
     if(this.type==1){
       if (this.printOrderInfo.length == 0) {
        this.$message.error("请选择要打印的订单");
      } else {
        let params = {
          is_old: is_old==1?is_old:0, // 0为新单号打印 1为原单号
          create_time:[],
          transaction_id:[],
          address_express_id: this.expressConsignerList[this.activeExpressConsignerIndex].id,
          template_id: this.expressTemplateList[this.expressTemplate]["id"],
        }
        
        this.orderInfoList.map((item)=>{
          if(item.is_hang != 1){
            params.create_time.push(item.create_time)
            params.transaction_id.push(item.transaction_id)
          }
        })
        
        this.$store.dispatch("print/GetExpressOrderInfo", params).then(res=>{
          this.dialogShow5 = false
          if(res.code == 0){
            if(res.message != "成功"){
              this.GetExpressError = res.message
            }
            if(res.data.length > 0){
              // params 参数： printer 打印机, waybillArray 订单数组 , preview = false //true预览 false打印
              let print_params = {
                printer: this.expressPrintOption.printer,
                preview: false,
                waybillArray:  res.data
              }
              let TaskID = window.pinduoduo_print.doPrint(print_params)
            }
            this.$store.dispatch("batchprint/get_search_result")
          }else{
            this.$message.error(res.message);
          }
        })
      }
     }
     if(this.type==2){
      let params = {
        is_old: is_old==1?is_old:0, // 0为新单号打印 1为原单号
        create_time: [this.orderInfoList.create_time],
        transaction_id: [this.orderInfoList.transaction_id],
        address_express_id: this.expressConsignerList[this.activeExpressConsignerIndex].id,
        template_id: this.expressTemplateList[this.expressTemplate]["id"],
      }

      this.$store.dispatch("print/GetExpressOrderInfo", params).then(res=>{
        if( res.code == 0){
          this.dialogShow5 = false
          if(res.message != "成功"){
            this.GetExpressError = res.message
          }
          if(res.data.length>0){
            // params 参数： printer 打印机, waybillArray 订单数组 , preview = false //true预览 false打印
            let print_params = {
              printer: this.expressPrintOption.printer,
              preview: false,
              waybillArray:  res.data
            }
            let TaskID = window.pinduoduo_print.doPrint(print_params)
          }
          this.$store.dispatch("singleprint/get_search_result")
        }else{
          this.$message.error(res.message);
        }
      })
     }
    },
    },
}
</script>


<style>

</style>