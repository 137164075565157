<template>
  <div class="search-form-container">
    <div class="search-form" ref="searchForm">
      <slot name="form">
        <el-form :inline="true" :rules="formRules" ref="ruleSearchForm" :model="searchInfo"
          :style="{ width: formWidth }">
          <el-form-item prop="Time">
            <el-select v-model="searchInfo.Time" placeholder="排序方式" size="mini" style="width: 110px">
              <el-option label="按下单时间" value="1"></el-option>
              <el-option label="按付款时间" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="startTime">
            <el-date-picker v-model="searchInfo.startTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
              :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']" size="mini" style="width: 310px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchInfo.status" placeholder="订单状态" size="mini" style="width: 110px">
              <el-option label="全部" value="0"></el-option>
              <!-- <el-option label="待扣款" value="1"></el-option> -->
              <el-option label="待发货" value="2"></el-option>
              <el-option label="已发货" value="3"></el-option>
              <el-option label="已签收" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item >
            <el-select v-model="searchInfo.print_state" placeholder="打印状态" size="mini" style="width: 110px">
              <el-option label="全部" value="0"></el-option>
              <el-option label="仅快递单已打印" value="1"></el-option>
              <el-option label="仅发货单已打印" value="2"></el-option>
              <el-option label="快递单发货单已打印" value="3"></el-option>
              <el-option label="快递单发货单未打印" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="searchInfo.quantity_type == '1'">
            <el-input v-model="searchInfo.quantity_value" placeholder="请输入查询数量" size="mini" style="width: 150px;">
            </el-input>
          </el-form-item>
          <el-form-item class="searchClass" v-if="showAll">
            <el-select v-model="searchInfo.ww_baby_count_money" placeholder="选择查询条件：名称/宝贝/数量/金额" id="searchId"
              size="mini" :style="{
                width: searchInfo.ww_baby_count_money == '' ? '230px' : '125px',
              }">
              <el-option label="选择查询条件：名称/宝贝/数量/金额" value=""></el-option>
              <el-option label="收件人" value="1"></el-option>
              <el-option label="宝贝总数量" value="2"></el-option>
              <el-option label="订单实付金额" value="3"></el-option>
              <el-option label="订单号" value="5"></el-option>
              <el-option label="快递单号" value="6"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="searchInfo.ww_baby_count_money == '1'">
            <el-input v-model="queryData.fxName" placeholder="请输入收件人" size="mini" style="width: 160px"
              @keyup.native="handleKeyNumber(queryData.fxName)">
              <i @click="buyWangShow(1)" slot="suffix" style="font-size: 16px; line-height: 43px; cursor: pointer"
                class="el-icon-edit-outline"></i>
            </el-input>
          </el-form-item>
          <el-form-item v-if="searchInfo.ww_baby_count_money == '2'">
            <el-input v-model="queryData.shopNumber" placeholder="请输入宝贝总数量" size="mini" style="width: 150px"
              @keyup.native="handleKeyNumber(queryData.shopNumber)">
              <!-- <i @click="buyWangShow(2)" slot="suffix" style="font-size: 16px; line-height: 43px; cursor: pointer"
                class="el-icon-edit-outline"></i> -->
            </el-input>
          </el-form-item>
          <el-form-item v-if="searchInfo.ww_baby_count_money == '3'">
            <el-input v-model="queryData.orderMoney" placeholder="请输入订单实付金额" size="mini" style="width: 150px;"
              @keyup.native="handleKeyNumber(queryData.orderMoney)">
              <!-- <i @click='buyWangShow(3)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;"
                class="el-icon-edit-outline"></i> -->
            </el-input>
          </el-form-item>
          <!-- <el-form-item v-if="searchInfo.ww_baby_count_money == '4'">
            <el-input v-model="queryData.supplierNumber" placeholder="请输入供货商单号" size="mini" style="width: 150px;"
              @keyup.native="handleKeyNumber(queryData.supplierNumber)">
              <i @click='buyWangShow(4)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;"
                class="el-icon-edit-outline"></i>
            </el-input>
          </el-form-item> -->
          <el-form-item v-if="searchInfo.ww_baby_count_money == '5'">
            <el-input v-model="queryData.smallShopNumber" placeholder="请输入订单号" size="mini" style="width: 150px;"
              @keyup.native="handleKeyNumber(queryData.smallShopNumber)">
              <i @click='buyWangShow(5)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;"
                class="el-icon-edit-outline"></i>
            </el-input>
          </el-form-item>
          <el-form-item v-if="searchInfo.ww_baby_count_money == '6'">
            <el-input v-model="queryData.expressNumber" placeholder="请输入快递单号" size="mini" style="width: 150px;"
              @keyup.native="handleKeyNumber(queryData.expressNumber)">
              <i @click='buyWangShow(6)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;"
                class="el-icon-edit-outline"></i>
            </el-input>
          </el-form-item>
          <el-form-item v-if="showAll">
            <el-select v-model="searchInfo.delivery_limit_type" placeholder="剩余发货时间" size="mini" style="width: 110px;">
              <el-option label="所有剩余时间" value="0"></el-option>
              <el-option label="已超时" value="1"></el-option>
              <el-option label="剩余时间小于24小时" value="2"></el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item v-if="showAll">
            <el-select
              v-model="searchInfo.refund_state"
              placeholder="退款状态"
              size="mini"
              style="width: 110px"
            >
              <el-option label="全部" value="0"></el-option>
              <el-option label="等待卖家同意" value="1"></el-option>
              <el-option label="等待买家退货" value="2"></el-option>
              <el-option label="等待卖家确认收货" value="3"></el-option>
              <el-option label="退款成功" value="4"></el-option>
              <el-option label="待买家修改" value="5"></el-option>
              <el-option label="退款失败" value="6"></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
      </slot>
    </div>
    <div class="search-menu">
      <!-- 表单折叠展开按钮 -->
      <el-button type="text" v-show="foldButton" @click="foldSearchForm"
        style="margin-right: 10px; color: #a1a1a1; text-decoration: none">
        {{ word }}
        <i :class="showAll ? 'el-icon-arrow-up' : 'el-icon-d-arrow-right'" style="color: #409eff"></i>

      </el-button>
      <!-- menu插槽 -->
      <slot name="menu">
        <el-button type="primary" size="mini" @click="Search">查询</el-button>
      </slot>
    </div>
    <el-dialog :visible.sync="dialogShow" width="30%" background-color="#fff" center :before-close='noneBtn'>
      <div class="contentClass">
        <p class="topBg"></p>
        <div class="topTitle">
          <span>批量添加</span>
          <span>批量添加（逗号换行）或从Excel中复制粘贴</span>
        </div>
        <div class="contentDiv">
          <p>当前最大添加数量{{ maxNumber }}条</p>
          <el-input type="textarea" :rows="8" v-model="addBuyValue" resize="none" @keyup.native="handleFontShow">
          </el-input>
          <p style="color: red" v-if="keyNumberShow">输入的内容已超过{{ maxNumber }}条</p>
        </div>
        <div class="contentFooter">
          <div @click="comfirBtn">确定</div>
          <div @click="noneBtn">取消</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogShow2" width="23%" background-color="#fff" center>
      <div class="contentClass contentClass2">
        <p class="topBg"></p>
        <div class="topTitle">
          <span>重新查询提醒</span>
        </div>
        <p class="contentText">
          页面停留时间过长，为避免订单信息变更同步不准确，请重新查询订单！
        </p>
        <div class="contentFooter">
          <div @click="dialogShow2 = false">继续打印</div>
        </div>
      </div>
    </el-dialog>
    <div class="print-show">
      <el-dialog :visible.sync="printShow" width="350px" :modal='false'>
        <p class="content"><i class="el-icon-warning"></i>最大可添加数量{{ maxNumber }}条</p>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrintSearchForm",
  props: {
    // 表单部分宽度，用来控制表单换行
    // 默认宽度为700px，如果需要更改换行则传递参数
    // 如果自己传入el-form，则不需要formWidth
    formWidth: {
      type: String,
      default() {
        return "700px";
      },
      required: false,
    },
    // 是否添加折叠按钮
    foldButton: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // 如果使用封装在内部的表单，则需要绑定表单数据
    formData: {
      type: Object,
      default() {
        return {
          // 查询按什么时间
          Time: "1",
          // 开始时间
          startTime: "",
          // 结束时间
          endTime: "",
          // 订单状态
          status: "",
          // 按什么查询
          ww_baby_count_money: "",
          // 订单类型
          trade_type: "",
          // 打印状态
          print_state: "",
          // 退款状态
          refund_state: "",
          // 分销商名称
          fxName: "",
          // 商品名称
          productName: "",
          // 页码
          pageNum: "1",
          // 每页个数
          limit: "20",
        };
      },
      required: false,
    },
  },
  data() {
    return {
      queryData: {
        fxName: '',//分销商名称
        shopNumber: '',//商品数量
        orderMoney: '',//订单实付金额
        supplierNumber: '',//供货商单号
        smallShopNumber: '',//订单号
        expressNumber: ''//快递单号
      },

      // 时间日期选择器选项
      pickerOptions: {
        shortcuts: [

          {
            text: "近1周",
            onClick(picker) {
              const end = new Date();
              const start = new Date(end - 86400 * 1000 * 6);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近1个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date(end - 86400 * 1000 * 29);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate: (date) => {
          // 限制查询3个月内数据
          let moment = this.moment;
          if (
            moment(date).isBefore(moment().subtract(2, "M").startOf("M")) ||
            moment(date).isAfter(moment().endOf("D"))
          ) {
            return true;
          }
        },
      },
      // 表单是否折叠控制参数
      showAll: true,
      // 表单验证规则
      formRules: {
        Time: [
          {
            required: true,
            message: "请选择查询条件",
            trigger: ["blur", "change"],
          },
        ],
        startTime: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
      },
      // 输入弹窗
      dialogShow: false,
      addBuyValue: "",
      inputNum: "",
      dialogShow2: false, // 页面停留时间过长
      checked: false,
      tagShow: false,
      keyNumber: 0,
      keyNumberShow: false,
      printShow: false,
      maxNumber: 20, //最大条数
      time_out_minutes: 0, // 分钟计时
    };
  },
  computed: {
    // 折叠按钮显示的内容
    // 表单折叠时显示 "更多选项"
    word: function () {
      if (this.showAll == false) {
        return "更多选项 ";
      } else {
        return "收起 ";
      }
    },
    // search-form绑定的数据
    // 计算searchInfo变化
    searchInfo: {
      // 获取初始值printFormData
      get() {
        return this.formData;
      },
      // 当searchInfo值发生变化时，调用updateFormData传值
      set(val) {
        this.$emit("updateFormData", val);
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.time_out_timer);
  },
  mounted() {
    this.$nextTick(function () {
      this.foldSearchForm();
    });
  },
  methods: {
    // 表单折叠展开函数
    foldSearchForm() {
      this.showAll = !this.showAll;
      var searchForm = this.$refs.searchForm;
      if (this.showAll == false) {
        searchForm.style.height = 50 + "px";
      } else {
        searchForm.style.height = "auto";
      }
    },
    // 停留超时计时
    timeOut() {
      clearTimeout(this.time_out_timer);
      this.time_out_timer = setTimeout(() => {
        this.dialogShow2 = true;
      }, 1000 * 60 * 5);
    },
    // 查询按钮，调用submitSearch
    Search() {
      this.$store.commit("singleprint/save", { singleprint_nodata: false })
      if (this.searchInfo.ww_baby_count_money == 1) {
        if (!this.queryData.fxName) {
          this.$message.error("收件人不能为空");
          return false
        } else {
          this.searchInfo.wbcm_value = this.queryData.fxName.replace(/，/g, ',')
        }
      }
      if (this.searchInfo.ww_baby_count_money == 2) {
        if (!this.queryData.shopNumber) {
          this.$message.error("宝贝总数量不能为空");
          return false
        } else {
          this.searchInfo.wbcm_value = this.queryData.shopNumber
        }
      }
      if (this.searchInfo.ww_baby_count_money == 3) {
        if (!this.queryData.orderMoney) {
          this.$message.error("订单实付金额不能为空");
          return false
        } else {
          this.searchInfo.wbcm_value = this.queryData.orderMoney
        }
      }
      // if (this.searchInfo.ww_baby_count_money == 4) {
      //   if (!this.queryData.supplierNumber) {
      //     this.$message.error("供货商单号不能为空");
      //     return false
      //   } else {
      //     this.searchInfo.wbcm_value = this.queryData.supplierNumber
      //   }
      // }
      if (this.searchInfo.ww_baby_count_money == 5) {
        if (!this.queryData.smallShopNumber) {
          this.$message.error("订单号不能为空");
          return false
        } else {
          this.searchInfo.wbcm_value = this.queryData.smallShopNumber.replace(/，/g, ',')
        }
      }
      if (this.searchInfo.ww_baby_count_money == 6) {
        if (!this.queryData.expressNumber) {
          this.$message.error("快递单号不能为空");
          return false
        } else {
          this.searchInfo.wbcm_value = this.queryData.expressNumber
        }
      }

      this.searchInfo.pageNum = 1
      this.$refs.ruleSearchForm.validate((valid) => {
        let moment = this.moment;
        if (
          !(
            moment(this.searchInfo.startTime[1]).diff(
              this.searchInfo.startTime[0],
              "day"
            ) < 35
          )
        ) {
          this.$message.error("一次搜索最多可选35天");
          return false;
        }
        if (valid) {
          // 停留超时计时
          this.time_out_minutes = 0;
          this.timeOut();
          this.$emit("submitSearch");
        } else {
          return false;
        }
      });
    },
    // dateTimePicker选择时间之后触发
    // timeChange (val) {
    //     // this.formData.endTime = this.dateTime[1];
    //     // this.formData.startTime = this.dateTime[0];
    // }
    buyWangShow(num) {
      this.inputNum = num;
      if (num == 1) {
        this.maxNumber=50 
        if (this.queryData.fxName) {
          this.addBuyValue = this.queryData.fxName
        } else {
          this.addBuyValue = ''
        }
      }//分销商名称

      // if (num == 2) {
      //   if (this.queryData.shopNumber) {
      //     this.addBuyValue = this.queryData.shopNumber
      //   } else {
      //     this.addBuyValue = ''
      //   }
      // }//宝贝总数量
      // if (num == 3) this.addBuyValue = this.queryData.orderMoney ? this.queryData.orderMoney : '' //订单实付金额
      if (num == 4) {
        this.maxNumber=200
        if (this.queryData.supplierNumber) {
          this.addBuyValue = this.queryData.supplierNumber
        } else {
          this.addBuyValue = ''
        }
      }
      
      // this.addBuyValue = this.queryData.supplierNumber ? this.queryData.supplierNumber : '' //供货商单号
      if (num == 5) {
        this.maxNumber = 200
        if (this.queryData.smallShopNumber) {
          this.addBuyValue = this.queryData.smallShopNumber
        } else {
          this.addBuyValue = ''
        }
      } //小店订单
      if (num == 6) {
        this.maxNumber=20
        if (this.queryData.expressNumber) {
          this.addBuyValue = this.queryData.expressNumber
        } else {
          this.addBuyValue = ''
        }
      }  //快递单号
      this.addBuyValue = this.addBuyValue.replace(/[,]/g, "\n").replace(/，/g, "\n");
      this.dialogShow = true;
    },
    comfirBtn() {
      let china = this.addBuyValue.replace(/，/g, ',')
      let alertMsg = china.replace(/\n/g, ",").split(",");
      if (alertMsg.length > this.maxNumber) {
        this.keyNumberShow = true;
        this.printShow = true;
      } else {
        // this.keyNumberShow = false;
        this.printShow = false;
        if (this.inputNum == 1) this.queryData.fxName = this.addBuyValue.replace(/\n/g, ",").replace(/，/g, ',');
        if (this.inputNum == 2) this.queryData.shopNumber = this.addBuyValue.replace(/\n/g, ",").replace(/，/g, ',');
        if (this.inputNum == 3) this.queryData.orderMoney = this.addBuyValue.replace(/\n/g, ",").replace(/，/g, ',');
        if (this.inputNum == 4) this.queryData.supplierNumber = this.addBuyValue.replace(/\n/g, ",").replace(/，/g, ',');
        if (this.inputNum == 5) this.queryData.smallShopNumber = this.addBuyValue.replace(/\n/g, ",").replace(/，/g, ',');
        if (this.inputNum == 6) this.queryData.expressNumber = this.addBuyValue.replace(/\n/g, ",").replace(/，/g, ',');
        this.dialogShow = false;
      }
    },
    noneBtn() {
      this.dialogShow = false;
      this.keyNumberShow = false;
      this.printShow = false;
    },

    handleKeyNumber(val) {
      let keyCode = val.replace(/，/g, ',').split(',')
      if (keyCode.length > this.maxNumber) {
        this.printShow = true;
      }
    },
    handleFontShow() {
      let china = this.addBuyValue.replace(/，/g, ',')
      let aa = china.replace(/\n/g, ",")
      let keyCode = aa.split(',')
      if (keyCode.length - 1 >= this.maxNumber) {
        this.keyNumberShow = true;
      } else {
        this.keyNumberShow = false;
      }
    },
    getBeginTime() {
      let now = new Date().getTime();
      let days = 34;
      let agoTimes = now - 86400 * 1000 * days;
      let time = new Date(agoTimes);
      let year = time.getFullYear();
      let mounth = time.getMonth() + 1;
      let date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 00:00:00";
    },
    getEndTime() {
      var time = new Date();
      var year = time.getFullYear();
      var mounth = time.getMonth() + 1;
      var date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 23:59:59";
    },
  },
  created() {
    this.searchInfo.startTime = [this.getBeginTime(), this.getEndTime()];
    this.searchInfo.Time = '1';
    this.searchInfo.status = '';
    this.searchInfo.presale_type = '';
    this.searchInfo.print_state = '';
    this.searchInfo.ww_baby_count_money = '';
    this.searchInfo.delivery_limit_type = '';
  },
};
</script>

<style lang="less" scoped>
.search-form-container {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  min-width: 1200px;
}

/deep/ .el-input--mini .el-input__inner:focus {
  border-color: #ff5400 !important;
}

.search-form {
  overflow-y: hidden;
}

/deep/ .iconfont {
  font-size: 14px;
}

/deep/ #searchId::placeholder {
  color: red;
}

/deep/ .searchClass .el-input__suffix .el-input__suffix-inner .el-select__caret {
  color: red;
}

/deep/ #searchId {
  color: red;
}

/deep/ .el-dialog {
  background-color: #fff;
}

/deep/ .el-dialog__header {
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog__close {
  font-size: 20px;
  font-weight: 600;
  color: #2c2b3b;
  line-height: 2 !important;
}

/deep/ .el-dialog__close:hover {
  color: #2c2b3b;
}

/deep/ .contentClass .el-textarea__inner,
.contentClass .el-textarea__inner:focus {
  border-color: #4f4f4f;
}

.contentClass {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 10px #999999;
}

.contentClass .topBg {
  width: 100%;
  height: 10px;
  background-color: #f6861e;
}

.contentClass .topTitle {
  padding: 0 10px;
  height: 30px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  border-bottom: 2px solid #e6e6e6;
}

.contentClass .topTitle span:nth-child(1) {
  font-size: 18px;
  color: #365064;
  font-weight: 600;
  margin-right: 10px;
}

.contentClass .topTitle span:nth-child(2) {
  color: #808080;
}

.contentDiv {
  padding: 0 20px;
  margin-bottom: 5px;
}

.contentDiv p {
  line-height: 35px;
  color: #808080;
}

.contentFooter {
  padding: 10px;
  background-color: #f3f3f3;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: right;
}

.contentFooter div {
  width: 55px;
  height: 24px;
  border: 1px solid #b4b4b4;
  color: #000;
  line-height: 24px;
  text-align: center;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  background-image: -webkit-linear-gradient(bottom, #e5e5e5, #f6f6f6);
}

.contentFooter div:nth-child(1) {
  border: none;
  color: #fff;
  background: linear-gradient(#ffc04b, #ff6302);
  margin-right: 20px;
}

.contentClass2 .contentFooter div {
  border: none;
  background: none;
  background-color: #ff7614;
  color: #fff;
  display: inline-block;
  padding: 9px 13px;
  border-radius: 4px;
  width: 60px;
  height: 18px;
  line-height: 18px;
}

.contentClass2 .topTitle {
  font-size: 16px;
}

.contentClass2 .contentText {
  color: #000;
  font-size: 14px;
  padding: 20px 30px 50px 10px;
}

/deep/ .el-checkbox__label {
  padding-left: 5px;
}

.el-icon-question {
  color: #e83437;
  margin-left: 5px;
  cursor: pointer;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f5b136;
  border-color: #f5b136;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #492900;
}

/deep/ .el-checkbox.is-bordered.is-checked {
  border-color: #f5b136;
}

/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #f5b136;
}

/deep/ .el-checkbox__inner {
  border-color: #666;
}

.clickTag {
  display: inline-block;
  position: relative;
  z-index: 200;

  div {
    width: 150px;
    background-color: #fafea7;
    border: 1px solid #fcca54;
    position: absolute;
    top: 10px;
    left: 25px;
    font-size: 14px;
    line-height: 14px;
    padding: 0 10px 10px 5px;

    p:nth-child(1) {
      font-weight: 600;
      margin: 10px 0;
    }
  }
}

.print-show .content {
  font-size: 20px;
  text-align: center;
  height: 140px;
  line-height: 140px;
}

.print-show /deep/ .el-icon-warning {
  color: #f6891e;
  font-size: 35px;
  transform: translate(-5px, 6px);
}

.print-show /deep/ .el-dialog__header {
  padding: 0px;
}
</style>