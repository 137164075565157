<template>
  <!-- 发货单设置发件人弹窗 -->
  <div>
    <my-dialog-3
      :centerDialogVisible="dialogFahuoConsignerVisible"
      :popupWidth="'500px'"
      @updateVisible="updateFahuoConsignerVisible"
      @openDialog="openFahuoConsignerDialog"
    >
      <template slot="content">
        <div class="consigner-dialog">
          <span style="margin-bottom: 30px">请选择发货单发件人</span>
          <el-radio-group v-model="activeFahuoConsignerIndex">
            <el-radio
              v-for="(item, index) in fahuoConsignerList"
              :key="index"
              :label="index + ''"
              >{{ item.title }}</el-radio
            >
          </el-radio-group>
          <span v-show="fahuoConsignerList.length == 0"
            >暂无发件人信息，点击<el-button type="text" @click="jumptoSetting()"
              >这里</el-button
            >进行设置</span
          >
        </div>
      </template>
      <template slot="footer">
        <el-button
          class="footer-btn btn1"
          type="primary"
          @click="handleChooseFahuoConsigner"
          size="mini"
          >确定</el-button
        >
        <el-button
          class="footer-btn btn2"
          size="mini"
          @click="handleCancel"
          >取消</el-button
        >
      </template>
    </my-dialog-3>
    <!-- 打印发货单弹窗 -->
    <!-- <my-dialog-3
      :centerDialogVisible="dialogFahuoVisible"
      :popupWidth="'500px'"
      @updateVisible="updateFahuoVisible"
    >
      <template slot="content">
        <div class="fahuo-print-dialog">
          <el-form
            label-position="right"
            label-width="120px"
            :model="fahuoPrintOption"
          >
            <el-form-item v-if="this.type==1" label="打印行数：">
              <span>共{{ selectRowLength }}行</span>
            </el-form-item>
            <el-form-item label="选择打印机：">
              <el-select
                v-model="fahuoPrintOption.printer"
                placeholder="选择打印机"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in printerList"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设置打印份数：">
              <el-select
                v-model="fahuoPrintOption.num"
                placeholder="选择份数"
                size="mini"
              >
                <el-option label="1" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template slot="footer">
        <el-button
          class="footer-btn btn1"
          type="primary"
          size="mini"
          @click="doPrintFahuo"
          >打印发货单</el-button
        >
        <el-button
          class="footer-btn btn2"
          size="mini"
          @click="dialogFahuoVisible = false"
          >取消</el-button
        >
      </template>
    </my-dialog-3> -->
  </div>
</template>

<script>
import {mapState} from 'vuex'
import myDialog3 from "@/UI/myDialog3";
import { lodopBatchPrint , lodopSinglePrint} from "@/utils/functions.js";
export default {
  props:['selectRowLength','orderInfoList','type'],
  data() {
    return {
      // 打印机列表
      printerList: this.PRINTERLIST || window.pinduoduo_print_list,
      // 发货打印确认弹窗变量
      dialogFahuoVisible: false,
      // 发货单发货人设置弹窗变量
      activeFahuoConsignerIndex: "0",
      fahuoConsigner: {},
      // 发货打印确认弹窗绑定数据
      fahuoPrintOption: {
        printer: "",
        num: "1",
      },     
    }
  },
  components:{
    myDialog3
  },
  computed: {
    ...mapState({
      fahuoConsignerList: (state) => state.print.fahuoConsignerList,
       // 打印参数
      printOrderInfo: (state) => state.batchprint.printOrderInfo,
    }),
    dialogFahuoConsignerVisible:{
      get(){
          return this.$store.state.batchprint.dialogFahuoConsignerVisible
      },
    }
  },
  methods: {
    handleCancel(){
      this.$store.commit('batchprint/SAVE',{dialogFahuoConsignerVisible:false})
    },
    // 发货单发件人设置弹窗函数
    updateFahuoConsignerVisible(val) {
      this.$store.commit('batchprint/SAVE',{dialogFahuoConsignerVisible:val})
    },
    openFahuoConsignerDialog() {
      this.$store.dispatch("print/get_fahuo_consigner_list");
    },
    handleChooseFahuoConsigner() {
      this.$store.commit('batchprint/SAVE',{dialogFahuoConsignerVisible:false})
      let fahuoConsigner = this.fahuoConsignerList[
        this.activeFahuoConsignerIndex
      ];
      this.fahuoConsigner = fahuoConsigner;
       this.doPrintFahuo()
    },
    // 发货弹窗函数
    updateFahuoVisible(val) {
      this.dialogFahuoVisible = val;
    },
    doPrintFahuo() {
      // if(!this.fahuoPrintOption.num || !this.fahuoPrintOption.printer ){
      //   this.$message.error("请选择打印机和打印份数");
      //   return false
      // }
       let printPrams={
          LODOP: this.LODOP,
          order_info_list: this.orderInfoList,
          consigner_info:this.fahuoConsigner
        }
      // type1批量打印，2单个打印
      if(this.type==1){
       if (this.printOrderInfo.length == 0) {
        this.$message.error("请选择要打印的订单");
      } else {
        let orderInfoList = this.orderInfoList.filter(item=>{
          return item.is_hang != 1
        })
        this.dialogFahuoVisible = false
        printPrams.order_info_list=orderInfoList
        lodopBatchPrint(printPrams).then(() => {
          for (let item of this.orderInfoList) {
            this.orderOidList = item.transaction_id + "|" + item.create_time + ",";
          }
          this.orderOidList = this.orderOidList.substring(
            0,
            this.orderOidList.lastIndexOf(",")
          );
          this.$store
            .dispatch("batchprint/update_fahuo_state", {
              transaction_id: this.orderOidList,
            })
            .then((res) => {
              this.orderOidList = "";
              this.$store.dispatch("batchprint/get_search_result")
            });
        });
      }
      }
      if(this.type==2){
      this.dialogFahuoVisible = false
      lodopSinglePrint(printPrams).then(() => {
        this.$store
          .dispatch("singleprint/update_fahuo_state", {
            transaction_id: this.orderInfoList.transaction_id + "|" + this.orderInfoList.create_time,
          })
          .then((res) => {
            this.$store.dispatch("singleprint/get_search_result")
          });
      });
      }
    },
  },

}
</script>

<style>

</style>